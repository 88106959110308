import styles from './inforButtonHelperText.module.scss';

export type InfoButtonHelperTextProps = {
  buttonId: string;
};

const InfoButtonHelperText = ({ buttonId }: InfoButtonHelperTextProps) => {
  return (
    <label
      className={`text-nowrap mb-0 ${styles.InfoButtonHelperText}`}
      htmlFor={buttonId}
    >
      Meer info
    </label>
  );
};

export default InfoButtonHelperText;
